
const spLoginAPI = "access-management/sp-login";

const serviceOption = "service-management/service-option";
const serviceStatus = "service-management/service-status";
const serviceType = "service-management/service-type";
const userType = "access-management/user-type";
const serviceProvider = "access-management/service-provider";
const serviceProviderDetails = "access-management/service-provider-details";
const availableServiceProvider = "access-management/available-service-provider";
const serviceProviderType = "access-management/service-provider-type";
const service = "service-management/service";
const sosService = "service-management/sos-service";
const serviceInfo = "service-management/service-info";
const serviceInclude = "service-management/service-include";
const serviceInfoType = "service-management/service-info-type";
const serviceAssign = "service-management/service-assign";
const category = "service-management/category";
const serviceOrder = "service-management/service-order";
const serviceProviderOrder="service-management/service-provider-order"
const order = "service-management/order";
const orderStatus = "service-management/order-status";
const assignCity = "service-management/assign-city";
const timeSolt="service-management/timeslot";
const subService="service-management/sub-service";
const user= "access-management/user"
const zone= "service-management/zone"
const state= "home-management/state"
const country= "home-management/country"
const dashboard= "home-management/dashboard"
const city= "home-management/city"
const permission = "access-management/permission"
const reportOrder = "report-management/order"
const reportServiceOrder = "report-management/service-order"
const serviceGroup="/service-management/service-group"

const customer = "access-management/user";

export { 
    serviceGroup,
    spLoginAPI,
    permission,
    reportServiceOrder,
    reportOrder,
    dashboard,
    subService,
    orderStatus,
    zone,
    assignCity,
    city,
    state,
    sosService,
    country,
    timeSolt,
    user,
    serviceInclude,
    serviceOption, 
    serviceProviderType,
    service,
    serviceStatus,
    serviceType,
    userType,
    category,
    serviceProvider,
    serviceAssign,
    serviceOrder,
    serviceProviderOrder,
    order,
    customer,
    serviceInfo,
    serviceInfoType,
    serviceProviderDetails,
    availableServiceProvider
}
